import * as React from "react";
import styled from "styled-components";
import { pr } from "../AppTheme";
import { useRockState } from "../Hooks/useRockState";
import { useWeb3React } from "../Hooks/useWeb3React";

const Container = styled.div`
  display: flex;
  margin: ${pr(30)} ${pr(30)} 0;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 959px) {
    justify-content: flex-start;
  }

  div.cell {
    width: 25%;
    display: flex;
    flex-direction: column;
    padding: ${pr(16)};

    @media (max-width: 959px) {
      width: 100%;
    }

    p {
      text-align: center;
      font-size: ${pr(18)};
      font-weight: bold;
    }

    .waifu-img {
      display: flex;
      justify-content: center;

      img {
        width: 50%;
      }
    }
  }
`;

export const All: React.FC = () => {
  const { state, fetchRocksOf } = useRockState(true);
  const { total, tokensOf } = state;
  const { account } = useWeb3React();

  /* const tokensOf = {
    [account!]: [0],
  }; */

  React.useEffect(() => {
    if (account) {
      fetchRocksOf(account);
    }
  }, []);

  return (
    <>
      <h1 style={{ textAlign: "center" }}>Wallet</h1>
      <Container>
        {account &&
          tokensOf[account]?.map((waifu) => (
            <div className="cell" key={`waifu-${waifu}`}>
              <p>Waifu #{waifu + 1}</p>
              <div className="waifu-img">
                <img alt="" src={`/img/tokens/${waifu + 1}.jpg`} />
              </div>
            </div>
          ))}
      </Container>
    </>
  );
};
