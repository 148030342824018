import * as React from "react";
import styled from "styled-components";
import { pr } from "../AppTheme";
import { useWeb3React } from "../Hooks/useWeb3React";
import { useInjected } from "../Contexts/InjectedContext";
import { useEagerConnect, useInactiveListener } from "../Hooks";
import { InjectedConnector } from "@web3-react/injected-connector";
import { UnsupportedChainIdError } from "@web3-react/core";
import { useChangeNetwork } from "../Hooks/useChangeNetwork";
import icon from "../Content/icon.png";
import { Link } from "react-router-dom";
import discordLogoWhite from "../Content/Discord-Logo-White.svg";
import tg from "../Content/telegram-logo.svg";

const Container = styled.div`
  padding: ${pr(24)} ${pr(64)};
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  position: fixed;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 959px) {
    max-width: unset;
    padding-left: ${pr(18)};
    padding-right: ${pr(18)};
    flex-wrap: wrap;
    position: unset;
  }

  div.left {
    display: flex;
    align-items: center;

    div.wallet {
      font-size: ${pr(18)};
      padding-left: ${pr(24)};
      font-weight: bold;
      color: black;

      :hover {
        text-decoration: underline;
      }
    }

    a {
      text-decoration: none;
    }

    img {
      cursor: pointer;
      max-height: 100%;
      min-height: 0;
      width: auto;
      height: ${pr(64)};
      display: block;
    }

    div.h {
      font-family: "ABeeZee", sans-serif;
      color: black;
      font-weight: bold;
      font-size: ${pr(32)};
    }
  }

  div.right {
    flex-grow: 1;
    padding-right: ${pr(16)};

    @media (max-width: 959px) {
      padding-right: 0;
    }
  }

  div.links {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    a {
      margin: 0 ${pr(12)};

      img {
        width: ${pr(32)};
        display: block;
      }
    }
  }
`;

const ConnectButton = styled.button`
  background-color: ${({ theme }) => theme.palette.red.main};
  font-weight: bold;
  font-size: ${pr(18)};
  padding: ${pr(6)} ${pr(32)} ${pr(8)};
  border: 1px solid white;
  cursor: pointer;
  border-color: black;
  border-radius: 1000px;

  @media (max-width: 959px) {
    font-size: ${pr(12)};
    width: 100%;
    margin-top: ${pr(12)};
  }

  :active {
    background-color: #888;
  }
`;

export const Header: React.FC = () => {
  const { activate, account, connector, error } = useWeb3React();
  const injected = useInjected();
  const changeNetwork = useChangeNetwork();

  const [activatingConnector, setActivatingConnector] = React.useState<
    InjectedConnector | null | undefined
  >();
  React.useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  const triedEager = useEagerConnect();
  useInactiveListener(!triedEager || !!activatingConnector);

  const handleConnect = React.useCallback(() => {
    if (injected && !account) {
      setActivatingConnector(injected);
      activate(injected);
    }
  }, [activate, injected, account]);

  React.useEffect(() => {
    if (error instanceof UnsupportedChainIdError) {
      changeNetwork();
    }
  }, [error, changeNetwork]);

  return (
    <Container>
      <div className="left">
        <Link to="/">
          <div className="h">AVAXFU</div>
        </Link>
        <Link to="/wallet">
          <div className="wallet">Wallet</div>
        </Link>
      </div>
      <div className="right">
        <div className="links">
          <a
            href="https://discord.gg/JTUJ6QvN5q"
            target="_blank"
            rel="noreferrer"
          >
            <img src={discordLogoWhite} alt="Discord" />
          </a>
          <a
            href="https://t.me/joinchat/WmtKm2b3Hcs2ZmM1"
            target="_blank"
            rel="noreferrer"
          >
            <img src={tg} alt="Telegram" />
          </a>
        </div>
      </div>
      <ConnectButton onClick={handleConnect}>
        {account
          ? `${account.substring(0, 6)}...${account.substring(
              account.length - 4
            )}`
          : "Connect"}
      </ConnectButton>
    </Container>
  );
};
