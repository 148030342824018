import { Provider } from "@ethersproject/providers";
import { Contract, ContractInterface, Signer } from "ethers/lib/ethers";
import * as React from "react";
import rocks from "../Abis/avaxfu.json";
import { avaxfuAddress } from "../constants";

function getContract(
  abi: ContractInterface,
  address: string,
  signerOrProvider: Signer | Provider
) {
  return new Contract(address, abi, signerOrProvider);
}

function getRocksContract(signerOrProvider: Signer | Provider) {
  return getContract(rocks, avaxfuAddress, signerOrProvider);
}

export function useRocksContract(signerOrProvider: Signer | Provider) {
  return React.useMemo(
    () => getRocksContract(signerOrProvider),
    [signerOrProvider]
  );
}
