import * as React from "react";
import { ThemeProvider } from "styled-components";
import { DefaultTheme } from "./AppTheme";
import { history } from "./history";
import { Router } from "react-router-dom";
import { AppRoutes } from "./AppRoutes";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { getInjected, InjectedContext } from "./Contexts/InjectedContext";

function getLibrary(provider: any): Web3Provider {
  return new Web3Provider(provider);
}

export const App: React.FC = () => {
  const [injected] = React.useState(() => getInjected());

  return (
    <ThemeProvider theme={DefaultTheme}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <InjectedContext.Provider value={injected}>
          <Router history={history}>
            <AppRoutes />
          </Router>
        </InjectedContext.Provider>
      </Web3ReactProvider>
    </ThemeProvider>
  );
};
