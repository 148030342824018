import * as React from "react";
import { OmitNative, RouteProps } from "react-router";
import { Route } from "react-router-dom";
import { Layout } from "./Layout";

type Props<T> = RouteProps<string> & OmitNative<T, keyof RouteProps>;

export const LayoutRoute: React.FC<Props<any>> = ({
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      component={undefined}
      render={(props) => (
        <Layout>{Component && <Component {...props} />}</Layout>
      )}
    />
  );
};
